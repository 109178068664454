/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { HeadingLevel } from '../components/shared/placeholder/RichTextActionBar';

type RichTextActionBarType = {
  setSelectedStyle: Dispatch<SetStateAction<HeadingLevel>>;
  selectedStyle: number;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const RichTextActionBarContext = createContext<RichTextActionBarType>(null!);
export const useRichTextActionBar = (): RichTextActionBarType => useContext(RichTextActionBarContext);
