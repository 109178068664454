/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fromMarkdown } from 'mdast-util-from-markdown';
import { Blockquote, Emphasis, Heading, Link, List, ListItem, Paragraph, Root, Strong, Text } from 'mdast-util-from-markdown/lib';
import { BaseEditor, Editor, Descendant } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import { v4 as uuid } from 'uuid';
import { MarkdownRenderersType } from './MarkdownRenderers';

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
type Content = ArrayElement<Root['children']>;

class MarkdownParser {
  input: string;
  ast: Root;
  result: any[] = [];
  currentStyles: string[] = [];
  currentParseTree: string[] = [];
  currentLists: any[] = [];

  constructor(markdown: string) {
    this.input = markdown;
    this.ast = fromMarkdown(markdown);

    this.result = [];
  }

  get lastElement() {
    let lastEl = this.result[this.result.length - 1];
    lastEl && (lastEl._parent = { children: this.result });
    while (lastEl?.children && lastEl.children[lastEl.children.length - 1]?.children) {
      const parent = lastEl;
      lastEl = lastEl.children[lastEl.children.length - 1];
      lastEl && (lastEl._parent = parent);
    }
    return lastEl?.children ? lastEl : { children: this.result, _parent: { children: this.result } };
  }

  get parseDepth() {
    return this.currentParseTree.length - 1;
  }

  public toSlateFormat(): any[] {
    for (const child of this.ast.children) {
      this.parse(child);
    }

    // remove `_parent` properties, as it causes circular errors when converting to JSON
    function rmParentProp(child: any) {
      if (child.children) child.children.forEach(rmParentProp);
      child._parent = undefined;
    }
    this.result.forEach(rmParentProp);

    return this.result;
  }

  private parse(child: Content) {
    type TThis = typeof this;
    type TMethods = { [TKey in keyof TThis]: TThis[TKey] extends (child: Content) => void ? TKey : never }[keyof TThis];
    const methodName = `parse_${child.type}` as TMethods;
    const method = this?.[methodName] as ((child: Content) => void) | undefined;

    this.currentParseTree.push(child.type);
    if (!method) {
      console.warn('failed to find method for ', child.type, child);
      this.parse_unknown(child);
    } else {
      method.bind(this)(child);
    }
    this.currentParseTree.pop();
  }

  private parse_paragraph(child: Paragraph) {
    const inList = this.currentParseTree.slice(0, -1).includes('list');
    const inBlockquote = this.currentParseTree.slice(0, -1).includes('blockquote');
    (inList || inBlockquote ? this.lastElement.children : this.result).push({
      type: 'paragraph',
      children: [],
    });

    if (!child.children.length) {
      this.lastElement.children.push({ id: uuid(), text: '', type: 'word' });
    }

    for (const c of child.children) {
      this.parse(c);
    }
  }

  private parse_text(child: Text) {
    const splitValue = child.value.split('\n');

    splitValue.forEach((value: string, i: number) => {
      if (i > 0) {
        this.lastElement._parent.children.push({
          type: 'paragraph',
          children: [],
        });
      }

      const fragments = value.split(/(\${{.*?}})/);
      const arrValue = fragments.map((fr: string) =>
        fr.startsWith('${{') && fr.endsWith('}}')
          ? {
              id: fr,
              type: 'placeholderContainer',
              children: [
                {
                  text: fr,
                  type: 'tag',
                },
              ],
            }
          : { id: uuid(), text: fr, type: 'word', styles: [...this.currentStyles] },
      );

      if (!arrValue?.length) return;
      this.lastElement.children.push(...arrValue);
    });
  }

  private parse_strong(child: Strong) {
    this.currentStyles.push('strong');

    for (const c of child.children) {
      this.parse(c);
    }

    this.currentStyles.pop();
  }

  private parse_emphasis(child: Emphasis) {
    this.currentStyles.push('em');

    for (const c of child.children) {
      this.parse(c);
    }

    this.currentStyles.pop();
  }

  private parse_list(child: List) {
    const inList = this.currentParseTree.slice(0, -1).includes('list');
    const inBLockquote = this.currentParseTree.slice(0, -1).includes('blockquote');

    const list = {
      type: (child.ordered ? 'ordered' : 'unordered') + '-list',
      children: [],
    };
    (inList || inBLockquote ? this.lastElement.children : this.result).push(list);

    this.currentLists.push(list);
    for (const c of child.children) {
      this.parse(c);
    }

    this.currentLists.pop();
  }

  private parse_listItem(child: ListItem) {
    this.currentLists[this.currentLists.length - 1].children.push({
      type: 'li',
      children: [],
    });

    for (const c of child.children) {
      this.parse(c);
    }

    if (!child.children.length) {
      this.lastElement.children.push({ id: uuid(), text: '', type: 'word' });
    }
  }

  private parse_break() {
    this.lastElement.children.push({ id: uuid(), text: '', type: 'break', styles: [...this.currentStyles] });
  }

  private parse_heading(child: Heading) {
    this.result.push({ type: 'heading', level: child.depth, children: [] });
    if (child.children.length > 0) {
      for (const c of child.children) {
        this.parse(c);
      }
    } else {
      this.lastElement.children.push({ type: 'word', text: '', id: uuid() });
    }
  }

  private parse_link(child: Link) {
    this.lastElement.children.push({ type: 'word', text: '', id: uuid() });
    this.lastElement.children.push({ type: 'link', url: child.url, children: [] });
    for (const c of child.children) {
      this.parse(c);
    }
    this.lastElement.children.push({ type: 'word', text: '', id: uuid() });
  }

  private parse_thematicBreak() {
    this.result.push({ type: 'thematic-break', children: [{ id: uuid(), text: '', type: 'word' }] });
  }

  private parse_blockquote(child: Blockquote) {
    let el = this.lastElement;
    while (el.type === 'paragraph') {
      el = el._parent;
    }
    el.children.push({ type: 'blockquote', children: [] });

    if (!child.children.length) {
      this.lastElement.children.push({ type: 'paragraph', children: [{ id: uuid(), text: '', type: 'word' }] });
    }

    for (const c of child.children) {
      this.parse(c);
    }
  }

  private parse_unknown(child: Content) {
    const { start, end } = child.position!;
    const originalText = this.input.slice(start.offset, end.offset);
    this.parse_text({ type: 'text', value: originalText });
  }
}

class SlateParser {
  slateObj: any[];
  result = '';
  currentStyles = [];
  lists: ('ordered-list' | 'unordered-list')[] = [];
  inBlockquote = 0;

  constructor(slateObj: any[]) {
    this.slateObj = slateObj;
  }

  public toMarkdown(): string {
    for (const child of this.slateObj) {
      this.parse(child);
    }

    return this.result.slice(0, -1).trim();
  }

  private parse(child: any) {
    switch (child.type) {
      case 'paragraph':
        this.parseParagraph(child);
        if (this.lists.length === 0 && !this.inBlockquote) {
          this.result += '\n';
        }
        break;
      case 'word':
        this.parseFragment(child);
        this.removeOldStyles(this.currentStyles);
        this.currentStyles = [];
        break;
      case 'unordered-list':
        this.parseList('unordered-list', child);
        break;
      case 'ordered-list':
        this.parseList('ordered-list', child);
        break;
      case 'heading':
        this.result += '\n' + new Array(child.level).fill('#').join('') + ' ';
        child.children.forEach((el: any) => {
          this.parse(el);
        });
        this.result += '\n';
        break;
      case 'link':
        this.result += '[';
        child.children.forEach((el: any) => {
          this.parse(el);
        });
        this.result += '](' + child.url + ')';
        break;
      case 'placeholderContainer':
        this.result += child.children[0].text;
        break;
      case 'thematic-break':
        this.result += '\n---\n';
        break;
      case 'blockquote':
        this.parseBlockquote(child);
        break;
      case 'break':
        this.result += '  \n';
        break;
      default:
        throw new Error('Unknown type ' + child.type + ' while turning to md. SJS was' + JSON.stringify(this.slateObj));
    }
  }

  private parseBlockquote(child: any) {
    if (this.inBlockquote === 0) {
      this.result += '\n';
    }

    child.children.forEach((el: any, i: number) => {
      if (this.inBlockquote && i !== 0) {
        this.result += '\n';
      }

      this.inBlockquote += 1;
      this.result += new Array(this.inBlockquote).fill('> ').join('');
      this.parse(el);
      this.inBlockquote -= 1;
    });

    if (this.inBlockquote === 0) {
      this.result += '\n';
    }
  }

  private parseList(type: 'unordered-list' | 'ordered-list', child: any) {
    this.lists.push(type);
    this.result += '\n';
    child.children.forEach((el: any, i: number) => {
      this.parseListItem(el, i);
    });
    this.result += '\n';
    this.lists.pop();
  }

  private parseParagraph(child: any) {
    for (const c of child.children ?? []) {
      this.parse(c);
    }

    this.result += '\n';
  }

  parseListItem(child: any, index: number) {
    if (!child.children?.length) {
      return;
    }

    const prefix = new Array(this.lists.length - 1).fill('  ').join('');
    if (this.lists[this.lists.length - 1] === 'unordered-list') {
      this.result += prefix + '- ';
    } else {
      this.result += prefix + (index + 1) + '. ';
    }

    for (const c of child.children) {
      this.parse(c);
    }
  }

  private parseFragment(fragment: any) {
    const preWhitespace = /^(\s*).*/.exec(fragment.text);
    const newStyles = (fragment.styles ?? []).filter((x: string) => !this.currentStyles.find((s) => x === s));
    const oldStyles = this.currentStyles.filter((x) => !(fragment.styles ?? []).find((s: string) => x === s));
    let text: string = fragment.text.trimStart();
    if (this.inBlockquote) {
      text = text.split('\n').join('\n' + new Array(this.inBlockquote).fill('> ').join(''));
    }

    this.removeOldStyles(oldStyles);
    this.result += preWhitespace?.[1] ?? '';
    this.addNewStyles(newStyles);
    this.result += text;

    this.currentStyles = fragment.styles ?? [];
  }

  private removeOldStyles(styles: string[]) {
    // matches spaces at end of text
    const whitespace = /.*?(\s*)$/.exec(this.result);

    if (whitespace?.[1]) {
      this.result = this.result.trimEnd();
    }

    for (const style of styles) {
      if (style === 'em') {
        this.result += '*';
      } else if (style === 'strong') {
        this.result += '**';
      }
    }

    this.result += whitespace?.[1] || '';
  }

  private addNewStyles(styles: string[]) {
    for (const style of styles) {
      if (style === 'em') {
        this.result += '*';
      } else if (style === 'strong') {
        this.result += '**';
      }
    }
  }
}

export const parseMarkdownToWords = (markdown: string): any[] => {
  if (!markdown?.trim()) return [{ id: uuid(), type: 'paragraph', children: [{ id: uuid(), text: '', type: 'word' }] }];
  const parser = new MarkdownParser(markdown);
  const result = parser.toSlateFormat();
  return result;
};

export const parseSlateJsToMarkdown = (slateObj: any): string => {
  if (!slateObj) return '';
  const parser = new SlateParser(slateObj);
  const result = parser.toMarkdown();
  return result;
};

const parseParagraphToWords = (parts: string[]): any[] => {
  if (parts.length === 0) {
    return [
      {
        id: uuid(),
        type: 'word' as any,
        text: '',
      },
    ];
  }

  const result = parts.map((wrd) => {
    if (wrd.startsWith('${{') && wrd.endsWith('}}')) {
      return {
        id: wrd,
        type: 'placeholderContainer' as any,
        children: [
          {
            text: wrd,
            type: 'tag' as any,
          },
        ],
      };
    }

    return { id: uuid(), text: wrd, type: 'word' };
  });

  if (result[result.length - 1].type !== 'word') {
    result.push({ id: uuid(), text: '', type: 'word' });
  }

  return result;
};

export const parseTextToWords = (value: string): Descendant[] => {
  return value
    .split('\n')
    .map((x) => x.split(/(\${{.*?}})/))
    .map((par) => ({
      type: 'paragraph',
      children: parseParagraphToWords(par),
    }));
};

export const toggleFormatting = (editor: BaseEditor & ReactEditor & HistoryEditor, format: any) => {
  const selection = editor.selection && Editor.nodes(editor, { at: editor.selection, match: (n) => 'type' in n && n.type === 'word' });
  let styles = selection ? [...selection].flatMap((x) => ('styles' in x[0] ? x[0].styles ?? [] : [])) : [];
  styles = styles.concat(getMarkStyles(editor));
  const isActive = styles.some((x) => x === format);

  if (isActive) {
    Editor.addMark(
      editor,
      'styles',
      styles.filter((x) => x !== format),
    );
  } else {
    styles.push(format);
    Editor.addMark(editor, 'styles', styles);
  }
};

export const getMarkStyles = (editor: BaseEditor & ReactEditor & HistoryEditor): MarkdownRenderersType[] => {
  return (editor.marks as any)?.styles ?? [];
};
