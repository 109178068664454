import { FC, ReactNode, useMemo } from 'react';
import { Editor, Transforms } from 'slate';
import InternalDynamicDataIcon from '../icon/InternalDynamicDataIcon';
import XIcon from '../icon/XIcon';
import { Action, FieldLabelLengthLimit, FormBuilderForm } from '../../form-builder/FormBuilderTypes';
import StringUtils from '../../../utils/StringUtils';
import { FormConfig } from '../../../models/Form';
import ExternalDynamicDataIcon from '../icon/ExternalDynamicDataIcon';
import MarkdownRenderers, { HEADINGS, LISTS, MarkdownRenderersType } from '../../../utils/MarkdownRenderers';
import ActionTypes from '../../form/ActionTypes';
import { useTranslation } from 'react-i18next';

const leafRenderer = MarkdownRenderers({});

export type PlaceholderWordProps = {
  id: string;
  form: FormBuilderForm;
  referencedForms: Record<string, FormConfig>;
  styles?: MarkdownRenderersType[];
  textboxDisabled?: boolean;
} & (
  | {
      children: ReactNode;
    }
  | {
      children?: ReactNode;
      isTag: true;
      editor: Editor;
      onEdit?: () => void;
      onDelete?: () => void;
    }
);

const PlaceholderWord: FC<PlaceholderWordProps> = (props) => {
  const { id, form, children, referencedForms, styles, textboxDisabled } = props;

  const { i18n } = useTranslation();

  const isTag = useMemo(() => ('isTag' in props ? props.isTag : undefined), [props]);
  const editor = useMemo(() => ('editor' in props ? props.editor : undefined), [props]);
  const onEdit = useMemo(() => ('onEdit' in props ? props.onEdit : undefined), [props]);
  const onDelete = useMemo(() => ('onDelete' in props ? props.onDelete : undefined), [props]);

  const ownFormActions = useMemo(() => form?.sections.flatMap((x) => x.actions) || [], [form?.sections]);

  const isSecondaryData = useMemo(() => {
    const placeholder = form?.placeholders?.find((x) => x.placeholder === id);
    return !ownFormActions.find((x) => x.id === placeholder?.referencedActionId);
  }, [form?.placeholders, id, ownFormActions]);

  const formActions = useMemo(() => {
    const refrencedActions = Object.values(referencedForms)
      .flatMap((x) => x.sections)
      .flatMap((x) => x.actions)
      .map((x) => ({ ...x }) as Action);

    return ownFormActions.concat(refrencedActions);
  }, [ownFormActions, referencedForms]);

  const displayLabel = useMemo(() => {
    const placeholder = form?.placeholders?.find((x) => x.placeholder === id);
    const action = formActions.find((x) => x.id === placeholder?.referencedActionId);
    return !action
      ? '[unknown placeholder]'
      : placeholder?.friendlyName ||
          (action.data
            ? action.data?.fieldLabel || StringUtils.toLowerDashed(ActionTypes[action.type].actionTitle(action, i18n.language), FieldLabelLengthLimit)
            : '[label missing]');
  }, [form?.placeholders, formActions, i18n.language, id]);

  if (!isTag) {
    let word = (
      <span className={`${textboxDisabled ? '' : 'text-black'} cursor-text leading-[25px] outline-none`} data-placeholder-role="word">
        {children}
      </span>
    );
    for (const style of styles || []) {
      if (HEADINGS.includes(style)) {
        word = leafRenderer[style]({ children: word, level: style.replace('h', '') });
      } else if (LISTS.includes(style)) {
        word = leafRenderer[style]({ children: leafRenderer['li']({ children: word }) });
      } else {
        word = leafRenderer[style]({ children: word });
      }
    }
    return word;
  }

  return (
    <span
      className={`bg-primary-1 text-dpm-14 my-1 inline-flex cursor-default select-none items-center whitespace-nowrap rounded-2xl px-2 align-middle font-normal text-white ${'opacity-100'} leading-[25px] outline-none`}
      contentEditable={false}
      data-placeholder-role="tag"
      data-tag-id={id}
      onDoubleClick={onEdit}
    >
      <span className={`mr-1 flex w-5 items-center transition-all duration-500`}>
        {!isSecondaryData && <InternalDynamicDataIcon className="inline h-4 w-4 text-white" />}
        {isSecondaryData && <ExternalDynamicDataIcon className="inline h-4 w-4 text-white" />}
      </span>
      {displayLabel}
      {children}
      <span
        onClick={() => {
          editor && Transforms.removeNodes(editor, { at: [], match: (node) => 'id' in node && node.id === id });
          onDelete && onDelete();
        }}
        className="ml-1 flex cursor-pointer items-center transition-opacity"
      >
        <XIcon className="h-4 w-4 text-white" />
      </span>
    </span>
  );
};

export default PlaceholderWord;
